import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import styled, { css } from "styled-components";
import Img from "gatsby-image";
import logoImg from "../images/logo-icon.png";
import { LogoImg } from "../components/Logo";
import AmazonDetailListing from "../components/AmazonListing";

import {
  Layout,
  Article,
  Wrapper,
  Button,
  SectionTitle,
  Subline,
  Content,
  Hero,
  LoadingSubline
} from "../components";
import {
  StyledH3,
  SecondaryH2,
  StyledInput
} from "../components/StyledElements";
import TensorFlowComponent from "../tensorflow";
import FancyHeader from "../components/FancyHeader";
import GeneratedElement, {
  GeneratedElementList
} from "../components/GeneratedElement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs } from "@fortawesome/free-solid-svg-icons";

const generatorPage = ({}) => (
  <Layout>
    <Wrapper>
      {/* <FancyHeader /> */}
      <TensorFlowComponent
        render={({
          loadModel,
          model,
          loading,
          getSummary,
          runModel,
          names,
          generating,
          seedText,
          handleSeedTextChange
        }) => {
          return (
            <React.Fragment>
              <div
                css={css`
                  grid-area: header;
                `}
              >
                <FancyHeader />
              </div>

              <Content>
                <SecondaryH2>FANTASY NAME GENERATOR</SecondaryH2>
                <SectionTitle>
                  <StyledInput
                    id={"seedText"}
                    label="Enter seed text below"
                    value={seedText}
                    onChange={handleSeedTextChange}
                  />
                  <Button big onClick={runModel}>
                    <FontAwesomeIcon icon={faCogs} />
                    Generate Names
                  </Button>
                  {loading && <LoadingSubline>LOADING</LoadingSubline>}
                  {generating && <LoadingSubline>algorithming</LoadingSubline>}
                </SectionTitle>
                <GeneratedElementList>
                  {names.map(name => {
                    return (
                      <GeneratedElement name={name} type={"name"} key={name}>
                        {name}
                      </GeneratedElement>
                    );
                  })}
                </GeneratedElementList>
              </Content>
            </React.Fragment>
          );
        }}
      />
    </Wrapper>
  </Layout>
);

export default generatorPage;

generatorPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      nodes: PropTypes.array.isRequired
    })
  }).isRequired
};

// export const IndexQuery = graphql`
//   query generator {
//     allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
//       nodes {
//         excerpt(pruneLength: 200)
//         timeToRead
//         fields {
//           slug
//         }
//         frontmatter {
//           title
//           date(formatString: "MM/DD/YYYY")
//           categories
//           primaryColor
//           banner {
//             ...bannerImage640
//           }
//         }
//       }
//     }
//   }
// `;
